@import "./../../../styles/variables.scss";

.xgs-forgot-password {
  &__notes {
    font-size: 14px;
    line-height: 20px;
    color: $gray3;
    margin-top: 36px;
  }

  &__success {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    color: $gray2;
    margin-top: 36px;
  }
}
