@import "./../../styles/variables.scss";
@import "./../../styles/mixins.scss";

.xgs-login {
  &__wrapper {
    background-color: $gray6;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-x: hidden;
  }

  &__content {
    width: 450px;
    margin-top: 64px;
    padding: 56px 64px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include up-to(461px) {
      width: 100%;
      padding: 56px 32px;
    }
  }

  &__title {
    height: 32px;
    margin-bottom: 8px;
    font-size: 24px;
    font-weight: bold;
    line-height: 28px;
    color: $gray0;

    @include up-to(360px) {
      font-size: 22px;
    }
  }

  &__button {
    margin-top: 48px;
    margin-bottom: 32px;
    width: 100%;
    height: 40px;
    font-size: 16px;
  }

  .form-items {
    height: auto;
    width: 100%;

    .xgs-labeled-input {
      margin-top: 24px;
      font-size: 14px;

      label {
        height: 24px;
      }
    }
  }
}
