@import "./../../../styles/variables.scss";
@import "./../../../styles/mixins.scss";

.xgs-exclamation {
  width: 433px;
  max-width: 100%;
  padding: $margin6;
  display: flex;
  flex-direction: row;
  align-items: center;

  @include up-to(500px) {
    width: 100%;
  }

  &.success {
    color: $green1;
    border: 1px solid $green1;
  }

  &.error {
    color: $red;
    border: 1px solid $red;
  }

  &.warning {
    color: $orange;
    border: 1px solid $orange;
  }

  &.info {
    color: $green1;
    border: 1px solid $gray5;
  }

  &__icon-container {
    width: 64px;
    text-align: center;
    margin-right: $margin6;
    flex-shrink: 0;
  }

  &__icon {
    font-size: 48px;
  }

  &__message {
    color: $gray1;
  }
}